import React from "react";
import "./src/styles/global.css";

export const onRouteUpdate = () => {
  if (typeof window !== `undefined`) {
    window.scrollTo(0, 0);
  }
};

export const shouldUpdateScroll = (args) => {
  return false;
};
